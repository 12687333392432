export const SERVER_URL = 'https://idigest.gtinternational.org';

export function getAuthStr() {
  return 'Bearer '.concat(localStorage.getItem('access_token'));
}

export function logoutUser(logout) {
  if (logout) {
    setTimeout(() => document.location.reload(true), 3000);
  }
}
