import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { CssBaseline, withStyles } from '@material-ui/core';

import { Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
import Attendance from '../../pages/attendance';
import Checkup from '../../pages/checkup';
import Dashboard from '../../pages/dashboard';
import FourOhFour from '../../pages/fourOhFour';
import Groups from '../../pages/groups';
import Header from '../Header';
import Leaders from '../../pages/groups/leaders';
import Members from '../../pages/groups/members';
import NewCheckup from '../../pages/checkup/newCheckup';
import React from 'react';
import Results from '../../pages/checkup/results';
import Sidebar from '../Sidebar';
import Status from '../../pages/checkup/status';
import classnames from 'classnames';

const PrivateRoute = ({ component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem('access_token') ? (
          React.createElement(component, props)
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};

// optional cofiguration for alert
const options = {
  position: 'bottom center',
  timeout: 5000,
  offset: '30px',
  transition: 'scale'
};

const Layout = ({ classes, isSidebarOpened, ...props }) => (
  <div className={classes.root}>
    <CssBaseline />
    <BrowserRouter>
      <React.Fragment>
        <Header />
        <Sidebar />
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: isSidebarOpened
          })}>
          <div className={classes.fakeToolbar} />
          <AlertProvider template={AlertTemplate} {...options}>
            <Switch>
              <PrivateRoute exact path='/app/dashboard' component={Dashboard} />
              <PrivateRoute exact path='/app/groups/leaders' component={Leaders} />
              <PrivateRoute exact path='/app/groups/members' component={Members} />
              <PrivateRoute exact path='/app/groups' component={Groups} />
              <PrivateRoute exact path='/app/checkup/status' component={Status} />
              <PrivateRoute exact path='/app/checkup/results' component={Results} />
              <PrivateRoute path='/app/checkup/newCheckup' component={NewCheckup} />
              <PrivateRoute exact path='/app/checkup' component={Checkup} />
              <PrivateRoute exact path='/app/attendance' component={Attendance} />
              <PrivateRoute component={FourOhFour} />
            </Switch>
          </AlertProvider>
        </div>
      </React.Fragment>
    </BrowserRouter>
  </div>
);

const styles = (theme) => ({
  root: {
    display: 'flex',
    maxWidth: '100vw',
    overflowX: 'hidden'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    width: 'calc(100vw - 240px)',
    minHeight: '100vh'
  },
  contentShift: {
    width: `calc(100vw - ${240 + theme.spacing(6)}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  fakeToolbar: {
    ...theme.mixins.toolbar
  }
});

export default withStyles(styles)(Layout);
