import { Link, Redirect } from 'react-router-dom';
import { SERVER_URL, getAuthStr } from '../utils';

import Button from '@material-ui/core/Button';
import { Grid } from '@material-ui/core';
import Input from '@material-ui/core/Input';
import PageTitle from '../../components/PageTitle/PageTitle';
import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import axios from 'axios';
import { withAlert } from 'react-alert';

class Leaders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      leaders: this.props.location.state.item.users.filter((item) => item.isGroupLeader === 1),
      members: this.props.location.state.item.users,
      capacity: this.props.location.state.item.capacity,
      groupId: this.props.location.state.item.groupId,
      userInput: '',
      redirect: false
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  handleInputChange(e) {
    this.setState({ userInput: e.target.value });
  }

  async handleAdd(groupId) {
    if (!this.state.userInput) {
      this.props.alert.error('Please enter a valid User Id!');
      return;
    }
    var isLeader = this.state.leaders.find((leader) => leader.loginId === this.state.userInput);
    if (isLeader) {
      this.props.alert.error('Please enter a valid User Id!');
      return;
    }
    const ADDLEADER_ENDPOINT = `${SERVER_URL}/api/webAdmin/group/${groupId}/addLeader/${this.state.userInput}`;
    if (this.state.capacity === this.state.members.length) {
      var found = this.state.members.find((member) => member.loginId === this.state.userInput);
      if (!found) {
        this.props.alert.error('Error! Over the capacity of study group!');
        this.setState({ userInput: '' });
        return;
      }
    }
    try {
      const response = await axios.post(ADDLEADER_ENDPOINT, null, {
        headers: { Authorization: getAuthStr() }
      });
      if (response.status === 200) {
        this.setState({ redirect: true });
        this.props.alert.success('Success!');
      }
    } catch (e) {
      this.props.alert.error('Error! Request is failed!');
    } finally {
      this.setState({ userInput: '' });
    }
  }

  async handleDelete(groupId, loginId) {
    if (this.state.leaders.length < 2) {
      this.props.alert.error('Error! Cannot delete the last leader from the study group!');
      return;
    }
    const DEMOTELEADER_ENDPOINT = `${SERVER_URL}/api/webAdmin/group/${groupId}/demoteLeader/${loginId}`;
    try {
      const response = await axios.post(DEMOTELEADER_ENDPOINT, null, {
        headers: { Authorization: getAuthStr() }
      });
      if (response.status === 200) {
        this.setState({ redirect: true });
        this.props.alert.success('Success!');
      }
    } catch (e) {
      this.handleAlert('requestFail');
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to='/app/groups' />;
    }

    const styles = {
      input: {
        marginTop: 20,
        marginLeft: 0,
        marginBottom: 20
      },
      buttons: {
        add: {
          paddingLeft: 21,
          paddingRight: 21
        },
        cancel: {
          marginTop: 30,
          float: 'left'
        }
      },
      columns: {
        name: {
          width: '50%'
        },
        loginId: {
          width: '30%'
        },
        action: {
          width: '20%'
        }
      }
    };

    return (
      <React.Fragment>
        <PageTitle title='Study Group Leader' />
        <Grid container spacing={8}>
          <Grid item xs={12}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={styles.columns.name}>Display Name</TableCell>
                  <TableCell style={styles.columns.loginId}>User Id</TableCell>
                  <TableCell style={styles.columns.action} />
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.leaders.map((item) => (
                  <TableRow key={item.userId}>
                    <TableCell style={styles.columns.name}>{item.displayName}</TableCell>
                    <TableCell style={styles.columns.loginId}>{item.loginId}</TableCell>
                    <TableCell style={styles.columns.delete}>
                      <Button
                        style={styles.buttons.add}
                        variant='contained'
                        color='primary'
                        onClick={() => this.handleDelete(this.state.groupId, item.loginId)}>
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell style={styles.columns.name}>
                    <i>Use the User email registered in the App. </i>
                    <i>The new leader will be added to the study group.</i>
                  </TableCell>
                  <TableCell style={styles.columns.loginId}>
                    <Input
                      style={styles.input}
                      placeholder="new leader's id"
                      onChange={this.handleInputChange}
                      value={this.state.userInput}
                      type='string'
                    />
                  </TableCell>
                  <TableCell style={styles.columns.delete}>
                    <Button
                      style={styles.buttons.add}
                      variant='contained'
                      color='primary'
                      onClick={() => this.handleAdd(this.state.groupId)}>
                      Add
                    </Button>
                    {/* <Button onClick={this.handleAlert}>
                      Alert
                    </Button> */}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid>
        <div style={styles.buttons.cancel}>
          <Link className='button' to='/app/groups' style={{ textDecoration: 'none' }}>
            <Button variant='contained' color='primary'>
              Go Back
            </Button>
          </Link>
        </div>
      </React.Fragment>
    );
  }
}

export default withAlert()(Leaders);
