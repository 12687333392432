import {
  ArrowBack as ArrowBackIcon,
  AssignmentTurnedIn as AssignmentTurnedInIcon,
  BarChart as BarChartIcon,
  Home as HomeIcon,
  People as PeopleIcon
} from '@material-ui/icons';
import { Drawer, IconButton, List, withStyles } from '@material-ui/core';

import React from 'react';
import SidebarLink from './components/SidebarLink/SidebarLinkContainer';
import classNames from 'classnames';

const structure = [
  { id: 0, label: 'Home', link: '/app/dashboard', icon: <HomeIcon /> },
  { id: 1, label: 'Study Groups', link: '/app/groups', icon: <PeopleIcon /> },
  { id: 2, label: 'Checkup', link: '/app/checkup', icon: <BarChartIcon /> },
  { id: 3, label: 'Attendance', link: '/app/attendance', icon: <AssignmentTurnedInIcon /> }
];

const SidebarView = ({ classes, theme, toggleSidebar, isSidebarOpened, isPermanent, location }) => {
  return (
    <Drawer
      variant={isPermanent ? 'permanent' : 'temporary'}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened
        })
      }}
      open={isSidebarOpened}>
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={toggleSidebar}>
          <ArrowBackIcon classes={{ root: classNames(classes.headerIcon, classes.headerIconCollapse) }} />
        </IconButton>
      </div>
      <List className={classes.sidebarList}>
        {structure.map((link) => (
          <SidebarLink key={link.id} location={location} isSidebarOpened={isSidebarOpened} {...link} />
        ))}
      </List>
    </Drawer>
  );
};

const drawerWidth = 240;

const styles = (theme) => ({
  menuButton: {
    marginLeft: 12,
    marginRight: 36
  },
  hide: {
    display: 'none'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 40,
    [theme.breakpoints.down('sm')]: {
      width: drawerWidth
    }
  },
  toolbar: {
    ...theme.mixins.toolbar,
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  sidebarList: {
    marginTop: theme.spacing(6)
  },
  mobileBackButton: {
    marginTop: theme.spacing(0.5),
    marginLeft: theme.spacing(3),
    [theme.breakpoints.only('sm')]: {
      marginTop: theme.spacing(0.625)
    },
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  }
});

export default withStyles(styles, { withTheme: true })(SidebarView);
