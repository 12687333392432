import './styles.css';

import { Button, ButtonGroup, Paper, Typography } from '@material-ui/core';
import { SERVER_URL, getAuthStr, logoutUser } from '../utils';

import CircularProgress from '@material-ui/core/CircularProgress';
import PageTitle from '../../components/PageTitle/PageTitle';
import React from 'react';
import _ from 'lodash';
import axios from 'axios';
import { withAlert } from 'react-alert';

class Attendance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      groups: [],
      users: [],
      tabIndex: 0,
      loading: true,
      logout: false
    };
  }

  async componentDidMount() {
    try {
      const orgId = localStorage.getItem('orgId');
      const response = await axios.get(`${SERVER_URL}/api/webAdmin/attendance/${orgId}`, {
        headers: { Authorization: getAuthStr() }
      });
      if (response.status === 200) {
        this.setState({
          groups: response.data.groups,
          users: response.data.users,
          loading: false
        });
      }
    } catch (e) {
      this.setState({ error: true });
      this.props.alert.error('Request is failed!');
      if (e.response && e.response.status === 401) {
        this.props.alert.show('Unauthorized! Please login again!');
        localStorage.clear();
        this.setState({ logout: true });
      }
    }
  }

  getDateString(date) {
    const d = new Date(date);
    return `${d.getFullYear()}/${('0' + (d.getMonth() + 1)).slice(-2)}/${('0' + d.getDate()).slice(-2)}`;
  }

  getGroupAttendanceCount(group, date) {
    const index = group.findIndex((item) => this.getDateString(item.date) === this.getDateString(date));
    if (index === -1) {
      return '';
    }

    return group[index].users.length;
  }

  renderByGroupsSummary() {
    const { groups } = this.state;

    // get all dates
    const allDates = {};
    Object.keys(groups).forEach((name) => {
      groups[name].forEach((item) => {
        allDates[this.getDateString(item.date)] = 0;
      });
    });

    const sortedAllDates = Object.keys(allDates).sort().reverse();
    let index = 0;
    let groupIndex = 1;
    let rows = [];
    Object.keys(groups)
      .sort()
      .forEach((name) => {
        const data = [];
        sortedAllDates.forEach((date) => {
          const count = this.getGroupAttendanceCount(groups[name], date);
          if (typeof count === 'number') {
            allDates[date] += count;
          }
          data.push(count);
        });
        rows.push({ name, data });
      });
    return (
      <Paper elevation={3}>
        <div className='responsiveDiv'>
          <table id='attendance'>
            <thead>
              <tr>
                <th></th>
                {sortedAllDates.map((date) => (
                  <th key={index++}>{date}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>
                  <b>Total</b>
                </th>
                {sortedAllDates.map((date) => {
                  return (
                    <th key={index++}>
                      <b>{allDates[date]}</b>
                    </th>
                  );
                })}
              </tr>
              {rows.map((row) => (
                <tr key={index++}>
                  <td>
                    #{groupIndex++} {row.name}
                  </td>
                  {row.data.map((count) => {
                    return <td key={index++}>{count}</td>;
                  })}
                </tr>
              ))}
              <tr>
                <th>
                  <b>Total</b>
                </th>
                {sortedAllDates.map((date) => {
                  return (
                    <th key={index++}>
                      <b>{allDates[date]}</b>
                    </th>
                  );
                })}
              </tr>
            </tbody>
          </table>
        </div>
      </Paper>
    );
  }

  renderByGroupsDetails() {
    const { groups, users } = this.state;
    let index = 0;
    return Object.keys(groups)
      .sort()
      .map((name) => {
        // get all users from all dates - axis Y
        const allUsers = {};
        groups[name].forEach((item) => {
          item.allUsers.forEach((userId) => {
            allUsers[userId] = {};
          });
        });

        const groupData = _.cloneDeep(groups[name]).reverse();
        let userIndex = 1;
        return (
          <div key={index++} className='responsiveDiv'>
            <Typography style={{ marginTop: 20 }}>{name}</Typography>
            <table id='attendance'>
              <thead>
                <tr>
                  <th></th>
                  {groupData.map((item) => (
                    <th key={index++}>{item.date}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>
                    <b>Total</b>
                  </th>
                  {groupData.map((item) => (
                    <th key={index++}>
                      <b>{item.users.length}</b>
                    </th>
                  ))}
                </tr>
                {Object.keys(allUsers).map((user) => {
                  return (
                    <tr key={index++}>
                      <td>
                        #{userIndex++} {users[user].name}
                      </td>
                      {groupData.map((item) => {
                        return <td key={index++}>{item.users.indexOf(parseInt(user)) === -1 ? '' : '√'}</td>;
                      })}
                    </tr>
                  );
                })}
                <tr>
                  <th>
                    <b>Total</b>
                  </th>
                  {groupData.map((item) => (
                    <th key={index++}>
                      <b>{item.users.length}</b>
                    </th>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
        );
      });
  }

  renderByPersonDetails() {
    const { groups, users } = this.state;

    // get all dates and userAttendanceByDate[userId][date]
    const allDates = {};
    const userAttendanceByDate = {};
    Object.keys(users).forEach((userId) => {
      userAttendanceByDate[userId] = {};
    });
    Object.keys(groups).forEach((name) => {
      groups[name].forEach((item) => {
        allDates[this.getDateString(item.date)] = 0;
        item.users.forEach((userId) => {
          userAttendanceByDate[userId][this.getDateString(item.date)] = 1;
        });
      });
    });

    const sortedAllDates = Object.keys(allDates).sort().reverse();
    let index = 0;
    let userIndex = 1;

    Object.keys(users).forEach((userId) => {
      sortedAllDates.forEach((date) => {
        if (userAttendanceByDate[userId][date]) {
          allDates[date]++;
        }
      });
    });

    return (
      <Paper elevation={3}>
        <div className='responsiveDiv'>
          <table id='attendance'>
            <thead>
              <tr>
                <th></th>
                {Object.keys(allDates)
                  .sort()
                  .reverse()
                  .map((date) => (
                    <th key={index++}>{date}</th>
                  ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>
                  <b>Total</b>
                </th>
                {sortedAllDates.map((date) => {
                  return (
                    <th key={index++}>
                      <b>{allDates[date]}</b>
                    </th>
                  );
                })}
              </tr>
              {Object.keys(users).map((userId) => (
                <tr key={index++}>
                  <td>
                    #{userIndex++} {users[userId].name}
                  </td>
                  {sortedAllDates.map((date) => {
                    return <td key={index++}>{userAttendanceByDate[userId][date] ? '√' : ''}</td>;
                  })}
                </tr>
              ))}
              <tr>
                <th>
                  <b>Total</b>
                </th>
                {sortedAllDates.map((date) => {
                  return (
                    <th key={index++}>
                      <b>{allDates[date]}</b>
                    </th>
                  );
                })}
              </tr>
            </tbody>
          </table>
        </div>
      </Paper>
    );
  }

  render() {
    const { tabIndex, loading } = this.state;
    if (loading) {
      return (
        <>
          <PageTitle title='Attendance is Loading' />
          <CircularProgress />
          {logoutUser(this.state.logout)}
        </>
      );
    }

    return (
      <>
        <PageTitle title='Attendance' />
        <ButtonGroup color='primary' aria-label='outlined primary button group'>
          <Button variant={tabIndex === 0 ? 'contained' : ''} onClick={() => this.setState({ tabIndex: 0 })}>
            Groups summary
          </Button>
          <Button variant={tabIndex === 1 ? 'contained' : ''} onClick={() => this.setState({ tabIndex: 1 })}>
            Groups details
          </Button>
          <Button variant={tabIndex === 2 ? 'contained' : ''} onClick={() => this.setState({ tabIndex: 2 })}>
            Person details
          </Button>
        </ButtonGroup>
        <Typography style={{ marginTop: 20 }} />
        {tabIndex === 0 && <>{this.renderByGroupsSummary()}</>}
        {tabIndex === 1 && <>{this.renderByGroupsDetails()}</>}
        {tabIndex === 2 && <>{this.renderByPersonDetails()}</>}
      </>
    );
  }
}

export default withAlert()(Attendance);
