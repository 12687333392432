import { Person as AccountIcon, ArrowBack as ArrowBackIcon, Menu as MenuIcon } from '@material-ui/icons';
import { AppBar, IconButton, Menu, Toolbar, withStyles } from '@material-ui/core';

import React from 'react';
import { Typography } from '../Wrappers';
import classNames from 'classnames';

const Header = ({ classes, isSidebarOpened, toggleSidebar, ...props }) => (
  <AppBar position='fixed' className={classes.appBar}>
    <Toolbar className={classes.toolbar}>
      <IconButton
        color='inherit'
        onClick={toggleSidebar}
        className={classNames(classes.headerMenuButton, classes.headerMenuButtonCollapse)}>
        {isSidebarOpened ? (
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse)
            }}
          />
        ) : (
          <MenuIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse)
            }}
          />
        )}
      </IconButton>
      <Typography variant='h6' weight='medium' className={classes.logotype}>
        Church Admin System
      </Typography>
      <div className={classes.grow} />

      <IconButton
        aria-haspopup='true'
        color='inherit'
        className={classes.headerMenuButton}
        aria-controls='profile-menu'
        onClick={props.openProfileMenu}>
        <AccountIcon classes={{ root: classes.headerIcon }} />
      </IconButton>
      <p>{localStorage.getItem('displayName')}</p>
      <Menu
        id='profile-menu'
        open={Boolean(props.profileMenu)}
        anchorEl={props.profileMenu}
        onClose={props.closeProfileMenu}
        className={classes.headerMenu}
        classes={{ paper: classes.profileMenu }}
        disableAutoFocusItem>
        <div className={classes.profileMenuUser}>
          <Typography
            variant='h4'
            weight='medium'
            className={classes.profileMenuLink}
            color='primary'
            onClick={props.signOut}>
            Log Out
          </Typography>
        </div>
      </Menu>
    </Toolbar>
  </AppBar>
);

const styles = (theme) => ({
  logotype: {
    color: 'white',
    marginLeft: theme.spacing(2.5),
    marginRight: theme.spacing(2.5),
    fontWeight: 500,
    fontSize: 18,
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  appBar: {
    width: '100vw',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  hide: {
    display: 'none'
  },
  grow: {
    flexGrow: 1
  },
  headerMenu: {
    marginTop: theme.spacing(7)
  },
  headerMenuList: {
    display: 'flex',
    flexDirection: 'column'
  },
  headerMenuItem: {
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.main,
      color: 'white'
    }
  },
  headerMenuButton: {
    marginLeft: theme.spacing(2),
    padding: theme.spacing.unit / 2
  },
  headerMenuButtonCollapse: {
    marginRight: theme.spacing(2)
  },
  headerIcon: {
    fontSize: 28,
    color: 'rgba(255, 255, 255, 0.35)'
  },
  headerIconCollapse: {
    color: 'white'
  },
  profileMenu: {
    minWidth: 265
  },
  profileMenuUser: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2)
  },
  profileMenuItem: {
    color: theme.palette.text.hint
  },
  profileMenuIcon: {
    marginRight: theme.spacing(2),
    color: theme.palette.text.hint
  },
  profileMenuLink: {
    fontSize: 16,
    textDecoration: 'none',
    '&:hover': {
      cursor: 'pointer'
    }
  }
});

export default withStyles(styles)(Header);
