import React from 'react';
import PageTitle from '../../components/PageTitle/PageTitle';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MUIDataTable from 'mui-datatables';
import CheckboxApply from './CheckboxApply';
import { withAlert } from 'react-alert';
import axios from 'axios';
import { SERVER_URL, getAuthStr } from '../utils';
const MEMBERS_ENDPOINT = `${SERVER_URL}/api/webAdmin/members`;
class NewCheckup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subject: '',
      startData: new Date().toISOString().substring(0, 10),
      endData: '',
      members: []
    };
  }

  async componentDidMount() {
    try {
      const response = await axios.get(MEMBERS_ENDPOINT, {
        headers: { Authorization: getAuthStr() }
      });
      if (response.status === 200) {
        this.setState({ members: response.data });
      }
    } catch (e) {
      this.props.alert.error('Error! Request is failed!');
    }
  }

  render() {
    const styles = {
      columns: {
        subject: { width: '50%' },
        startData: { width: '25%' },
        endData: { width: '25%' }
      },
      textField: {
        marginLeft: 30,
        marginRight: 30,
        width: 200
      },
      button: {
        marginTop: '30px',
        position: 'left'
      }
    };

    const options = {
      filter: true,
      responsive: 'stacked',
      rowsPerPage: 100,
      selectableRows: true,
      customToolbarSelect: (selectedRows) => (
        <CheckboxApply
          selectedRows={selectedRows}
          handleCheckbox={this.handleCheckbox}
          members={this.state.members}
          subject={this.state.subject}
          startData={this.state.startData}
          endData={this.state.endData}
        />
      )
    };
    const columns = [
      {
        name: 'displayName',
        label: 'Display Name',
        options: {
          filter: true
        }
      },
      {
        name: 'loginId',
        label: 'User Id',
        options: {
          filter: true
        }
      }
    ];

    return (
      <React.Fragment>
        <PageTitle title='New Checkup' />
        <Grid container spacing={32}>
          <Grid item xs={12}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={styles.columns.subject}>Subject</TableCell>
                  <TableCell style={styles.columns.startData}>Start Data</TableCell>
                  <TableCell style={styles.columns.endData}>End Data</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell style={styles.columns.subject}>
                    <TextField
                      styles={styles.textField}
                      margin='normal'
                      type='string'
                      value={this.state.subject}
                      onChange={(e) => this.setState({ subject: e.target.value })}
                    />
                  </TableCell>
                  <TableCell style={styles.columns.startData}>
                    <TextField
                      type='date'
                      styles={styles.textField}
                      value={this.state.startData}
                      onChange={(e) => this.setState({ startData: e.target.value })}
                    />
                  </TableCell>
                  <TableCell style={styles.columns.endData}>
                    <TextField
                      type='date'
                      styles={styles.textField}
                      value={this.state.endData}
                      onChange={(e) => this.setState({ endData: e.target.value })}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={styles.columns.subject}>
                    <i>Please Select Members To Join The Checkup</i>
                  </TableCell>
                  <TableCell style={styles.columns.startData} />
                  <TableCell style={styles.columns.endData}>
                    <i>Click Apply After Selection</i>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>

            <MUIDataTable title={'Members list'} data={this.state.members} columns={columns} options={options} />
          </Grid>
        </Grid>

        <div style={styles.button}>
          <Link className='button' to='/app/checkup' style={{ textDecoration: 'none' }}>
            <Button variant='contained'>Cancel</Button>
          </Link>
        </div>
      </React.Fragment>
    );
  }
}

export default withAlert()(NewCheckup);
