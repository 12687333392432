import { compose, withState, withHandlers } from 'recompose';
import { connect } from 'react-redux';

import HeaderView from './HeaderView';
import { signOut } from '../../pages/login/LoginState';
import { toggleSidebar } from '../Layout/LayoutState';

export default compose(
  connect(
    (state) => ({
      isSidebarOpened: state.layout.isSidebarOpened
    }),
    { signOut, toggleSidebar }
  ),
  withState('profileMenu', 'setProfileMenu', null),
  withHandlers({
    openProfileMenu: (props) => (event) => {
      props.setProfileMenu(event.currentTarget);
    },
    closeProfileMenu: (props) => () => {
      props.setProfileMenu(null);
    }
  })
)(HeaderView);
