import React from 'react';
import PageTitle from '../../components/PageTitle/PageTitle';

const FourOhFour = () => {
  return (
    <React.Fragment>
      <PageTitle title='Not Found' />
    </React.Fragment>
  );
};

export default FourOhFour;
