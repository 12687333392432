import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import themes, { overrides } from '../themes';

import Error from '../pages/error';
import Layout from './Layout';
import Login from '../pages/login';
import React from 'react';

const theme = createMuiTheme({ ...themes.default, ...overrides });

const PrivateRoute = ({ component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem('access_token') ? (
          React.createElement(component, props)
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};

const PublicRoute = ({ component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem('access_token') ? (
          <Redirect
            to={{
              pathname: '/'
            }}
          />
        ) : (
          React.createElement(component, props)
        )
      }
    />
  );
};

const App = () => (
  <MuiThemeProvider theme={theme}>
    <BrowserRouter>
      <Switch>
        <Route exact path='/' render={() => <Redirect to='/app/dashboard' />} />
        <Route exact path='/app' render={() => <Redirect to='/app/dashboard' />} />
        <PrivateRoute path='/app' component={Layout} />
        <PublicRoute path='/login' component={Login} />
        <Route component={Error} />
      </Switch>
    </BrowserRouter>
  </MuiThemeProvider>
);

export default App;
