import { SERVER_URL, getAuthStr, logoutUser } from '../utils';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from 'react-router-dom';
import PageTitle from '../../components/PageTitle/PageTitle';
import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import axios from 'axios';
import { withAlert } from 'react-alert';

const GROUPS_ENDPOINT = `${SERVER_URL}/api/webAdmin/groups`;

class Groups extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      groups: [],
      loading: true,
      logout: false
    };
    this.getLeaders = this.getLeaders.bind(this);
  }

  getLeaders(users) {
    let result = '';
    // eslint-disable-next-line no-unused-vars
    for (const user of users) {
      if (user.isGroupLeader === 1) {
        result += user.displayName + ';  ';
      }
    }
    return result.substring(0, result.length - 3);
  }

  async componentDidMount() {
    try {
      const response = await axios.get(GROUPS_ENDPOINT, {
        headers: { Authorization: getAuthStr() }
      });
      if (response.status === 200) {
        this.setState({
          groups: response.data,
          loading: false
        });
      }
    } catch (e) {
      this.setState({ error: true });
      this.props.alert.error('Error! Request is failed!');
      if (e.response && e.response.status === 401) {
        this.props.alert.show('Unauthorized! Please login again!');
        localStorage.clear();
        this.setState({ logout: true });
      }
    }
  }

  render() {
    if (this.state.loading) {
      return (
        <>
          <PageTitle title='Study Groups is Loading' />
          <CircularProgress />
          {logoutUser(this.state.logout)}
        </>
      );
    }

    const styles = {
      columns: {
        name: { width: '10%' },
        class: { width: '10%' },
        leader: { width: '40%' },
        edit: { width: '10%' },
        members: { width: '10%' }
      }
    };

    return (
      <>
        <PageTitle title='Study Groups' />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={styles.columns.name}>Group Name</TableCell>
              <TableCell style={styles.columns.class}>Class Name</TableCell>
              <TableCell style={styles.columns.leader}>Leader</TableCell>
              <TableCell style={styles.columns.edit} />
              <TableCell style={styles.columns.members} />
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.groups.map((item) => (
              <TableRow key={item.groupId}>
                <TableCell style={styles.columns.name}>{item.groupName}</TableCell>
                <TableCell style={styles.columns.class}>{item.className}</TableCell>
                <TableCell style={styles.columns.leader}>{this.getLeaders(item.users)}</TableCell>
                <TableCell style={styles.columns.edit}>
                  <Link
                    className='button'
                    style={{ textDecoration: 'none' }}
                    to={{
                      pathname: '/app/groups/leaders',
                      state: { item }
                    }}>
                    <Button color='primary'>Edit Leader(s)</Button>
                  </Link>
                </TableCell>
                <TableCell style={styles.columns.members}>
                  <Link
                    className='button'
                    style={{ textDecoration: 'none' }}
                    to={{
                      pathname: '/app/groups/members',
                      state: { item }
                    }}>
                    <Button color='primary'>Members List</Button>
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </>
    );
  }
}

export default withAlert()(Groups);
