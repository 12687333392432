import React from 'react';
import PageTitle from '../../components/PageTitle/PageTitle';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { getAuthStr } from '../utils';

const CHECKUP_RESULT_URL = 'https://web.7-stages.org/checkup/#/checkup/MinistryHealthSummary';
class Results extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkupId: this.props.location.state.item.checkupId,
      access_token: getAuthStr().substring(7),
      // checkupId: 34,
      // access_token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjEsImxvZ2luSWQiOiJ0ZXN0QDctc3RhZ2VzLm9yZyIsImlhdCI6MTU2MjQwNDE5MCwiZXhwIjoxNTkzOTQwMTkwfQ.UJ6quYtqhDaDJlQuKX-37frWkf7CGFwJw_8WqqsLQhg",
      language: 'eng'
    };
  }

  render() {
    const styles = {
      columns: {
        subject: {
          width: '20%'
        },
        results: {
          width: '10%'
        }
      },
      button: {
        marginTop: 30,
        float: 'left'
      }
    };

    return (
      <React.Fragment>
        <PageTitle title='Checkup Result' />
        <iframe
          title='Checkup Result'
          width='100%'
          height='70%'
          frameBorder='0'
          src={`${CHECKUP_RESULT_URL}/${this.state.checkupId}?token=${this.state.access_token}&lang=${this.state.language}`}
        />
        <div style={styles.button}>
          <Link className='button' to='/app/checkup' style={{ textDecoration: 'none' }}>
            <Button variant='contained' color='primary'>
              Go Back
            </Button>
          </Link>
        </div>
      </React.Fragment>
    );
  }
}

export default Results;
